@import "../../styles";

.root {
  position: absolute;
  top: 8%;
  left: 10%;
  width: 464px;
  border-radius: 8px;
  padding: 35px 59px 0 59px;
  background: linear-gradient(225deg, transparent 20px, #fcfcfc 20px);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 31px;
    height: 31px;
    border-bottom-left-radius: 8px;
    background: linear-gradient(225deg, transparent 20px, #d5d5d5 20px);

    @media (max-width: 980px) {
      display: none;
    }
  }

  @media (max-width: 1440px) {
    top: 6%;
  }

  @media (max-width: 980px) {
    position: relative;
    width: 100%;
    left: 0;
    background: white;
    padding: 35px 25px 0 25px;
  }

  .title {
    color: #575563;
    display: flex;
    align-items: center;
    margin-bottom: 29px;

    @media (max-width: 980px) {
      margin-bottom: 27px;
    }

    h3 {
      font-weight: 400;
      font-size: 22px;
      line-height: 30.8px;
      margin: 0;

      @media (max-width: 980px) {
        margin: 0;
        text-align: left;
        padding: 0 0 0 20px;
      }
    }
    img {
      height: 36px;
      margin-right: 20px;

      @media (max-width: 980px) {
        margin-right: 0;
      }
    }
    a {
      text-align: left;
      display: block;
      text-decoration: none;
      color: #575563;
      font-size: 22px;
      line-height: 30.8px;
      margin-left: 8px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  p {
    font-size: 14px;
    margin-bottom: 33px;
  }

  .FormWrapper {
    margin-bottom: 34px;
  }
}

.map {
  height: 680px;
  width: 100%;
  position: relative;

  @media (max-width: 980px) {
    display: none;
  }
}

.phoneWrapper {
  display: flex;
}
