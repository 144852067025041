.logisticsTop {
  @media (max-width: 992px) {
    padding-bottom: 0;
  }
  .title {
    padding-top: 195px;
    height: 491px;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;

    @media (max-width: 992px) {
      padding-top: 75px;
      justify-content: center;
    }

    h1 {
      font-family: "Titillium Web", sans-serif;
      font-size: 48px;
      font-weight: bold;
      line-height: 1.13;
      text-align: center;
      color: #ffffff;
      letter-spacing: 0.7px;
      @media (max-width: 992px) {
        font-size: 35px;
        line-height: 1.15;
        max-width: 94%;
      }
    }

    h2 {
      font-family: "Titillium Web", sans-serif;
      font-size: 32px;
      margin-top: 32px;
      line-height: 0.75;
      text-align: center;
      color: #ffffff;
      @media (max-width: 992px) {
        font-size: 18px;
        line-height: 1.33;
        padding: 14px;
        margin-top: 27px;
        max-width: 85%;
      }
    }
  }

  .logisticsText {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 40px;
    font-family: Roboto, sans-serif;
    line-height: 1.5;
    text-align: left;
    color: #323232;
    background: #ebebeb;
    padding-top: 62px;
    position: relative;

    p {
      white-space: break-spaces;
      break-inside: avoid;
      font-size: 16px;

      @media (min-width: 1500px) {
        font-size: 18px;
      }
    }
    @media (max-width: 992px) {
      display: block;
      padding-top: 17px;
      max-height: 476px;
      overflow: hidden;

      &.expanded {
        max-height: none;
      }
    }
    p {
      @media (min-width: 992px) {
        padding-bottom: 24px;
        margin-bottom: 0;
      }
    }
    .logisticsParagraph {
      padding-left: 158px;
      padding-right: 158px;
      @media (min-width: 992px) {
        column-count: 2;
        column-gap: 4rem;
      }
      @media (max-width: 992px) {
        padding: 19px 5px 5px;
        text-align: center;
        display: flex;

        p {
          max-width: 90%;
        }
      }
    }
  }
}
