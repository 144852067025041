@import "../../common/styles";

.head {
  padding-top: 195px;
  height: 491px;
  flex-direction: column;
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;

  @media(max-width: 992px) {
    padding-top: 125px;
    height: 424px;
    background-position: right top;
  }

  h1 {
    padding: 0 20px;
    font-family: 'Titillium Web', sans-serif;
    font-size: 48px;
    font-weight: bold;
    line-height: 1.13;
    text-align: center;
    color: #ffffff;
    letter-spacing: 0.7px;
    @media (max-width: 992px) {
      font-size: 35px;
      padding: 0;
      line-height: 1.15;
      max-width: 90%;
    }
  }

  h2 {
    font-family: 'Titillium Web', sans-serif;
    font-size: 32px;
    margin-top: 32px;
    line-height: 0.75;
    text-align: center;
    color: #ffffff;

    @media (max-width: 992px) {
      font-size: 18px;
      line-height: 1.33;
      padding: 14px;
      margin-top: 27px;
      max-width: 90%;
    }
  }
}

.installationText {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  min-height: 452px;
  line-height: 1.5;
  font-weight: 400;
  text-align: left;
  color: $semiblack;
  background-color: #ebebeb;
  margin-bottom: 6px;
  padding-top: 60px;
  position: relative;

  @media (max-width: 992px) {
    padding-top: 17px;
    max-height: 476px;
    overflow: hidden;
    padding-bottom: 0;
    font-size: 16px;

    &.expanded {
     max-height: none;
    }
  }

  @media (min-width: 1500px) {
    font-size: 18px;
  }

  p {
    margin-bottom: 24px;
    @media (max-width: 992px) {
      max-width: 90%;
    }
  }
  .installationParagraph1 {
    padding-left: 159px;
    padding-right: 41px;

    @media (max-width: 992px) {
      padding: 19px 19px 0;
      text-align: center;
      margin: 0;
    }
  }
  .installationParagraph2 {
    padding-left: 19px;
    padding-right: 157px;

    @media (max-width: 992px) {
      padding: 0 19px 19px;
      text-align: center;
    }
  }
}

