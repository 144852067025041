@import '../../../styles';

.root {
  position: fixed;
  left: 0;
  top: -10%;
  width: 100%;
  opacity: 0;
  transition: all 0.4s, top 0.5s;
  z-index: 10;
  overflow-x: hidden;
  overflow-y: auto;
  visibility: hidden;
}

.opened {
  opacity: 1;
  visibility: visible;
  transform: translateY(3%);
  overflow-y: auto;

  @media (max-width: 1730px) {
    transform: none;
  }

  @media (max-width: 770px) {
    transform: translateY(10%);
    max-height: 100vh;
  }
}

.content {
  background-color: white;
  margin: 10% auto;
  padding: 40px 20px 20px;
  border: none;
  width: 70%;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 770px) {
    margin: 2% 1% 30%;
    width: 98%;
    padding: 30px 10px;
  }

  h2 {
    font-family: "Titillium Web", sans-serif;
    font-weight: 500;
    font-size: 36px;
    color: $primary1;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  p {
    width: 85%;
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;

    @media (max-width: 1440px) {
      font-size: 14px;
    }
  }

  > span {
    @media (max-width: 768px) {
      text-align: center;
    }
  }

  >span > a {
    color: $primary2;
    text-decoration: none;
    border-bottom: 1px solid #D5D5D5;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    text-transform: none;
  }
}

.closeButton {
  color: #cccccc;
  position: absolute;
  right: 16px;
  top: 8px;
  font-size: 21px;
  font-weight: bold;

  &:hover, &:focus {
    color: #808080;
    text-decoration: none;
    cursor: pointer;
  }
}

.score {
  display: flex;
  margin-bottom: 60px;
  width: 100%;
  justify-content: center;

  @media (max-width: 1440px) {
    margin-bottom: 10px;
  }

  @media (max-width: 770px) {
    flex-direction: column;
    align-items: center;
  }

  img {
    max-width: 250px;

    @media (max-width: 1440px) {
      max-width: 200px;
    }

    @media (max-width: 770px) {
      max-width: 160px;
    }
  }
}

.scoreInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 120px;

  @media (max-width: 770px) {
    align-items: center;
    padding: 0;
  }

  h3 {
    color: $primary1;
    font-family: "Titillium Web", sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 35.2px;
    margin-bottom: 20px;

    @media (max-width: 1440px) {
      font-size: 28px;
    }
  }

  span {
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #575563;
    font-weight: 400;
    margin-bottom: 60px;

    @media (max-width: 1440px) {
      font-size: 12px;
      margin-bottom: 20px;
    }
  }
}
