@import "../../../common/styles";

.root {
  padding: 73px 10% 15px;
  @media (max-width: 992px) {
    margin-bottom: 0;
    padding: 35px 14px 11px;
  }
  h3 {
    text-align: center;
    font-family: 'Titillium Web', sans-serif;
    font-size: 44px;
    font-weight: bold;
    line-height: 1.09;
    margin-bottom: 72px;
    color: $semiblack;
    letter-spacing: 0.7px;
    @media (max-width: 992px) {
      letter-spacing: 0.1px;
      font-size: 22px;
      font-weight: bold;
      line-height: 1.09;
      margin-bottom: 37px;
      text-align: center;
      margin-top: 0;
      padding: 0 5%;
    }
  }
}

.brands {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 54px;
  }

  @media (max-width: 768px) {
    justify-content: space-around;
    flex-wrap: wrap;
  }

  img {
    max-width: 300px;

    @media (max-width: 1260px) {
      max-width: 250px;
    }

    @media (max-width: 768px) {
      margin: 16px;
      max-height: 40px !important;
    }
  }

  >a {
    @media (max-width: 768px) {
      max-width: 150px;
      max-height: 40px;
      margin: 20px 20px 20px 0;
    }
  }
}

.wideBrands {
  img {
    @media (max-width: 768px) {
      max-width: 40%;
      margin: 16px;
      max-height: 50px;

      &:last-child {
        max-width: 70%;
      }
    }
  }
}

.brandsMobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  > img {
    max-width: 40%;
    margin: 16px;

    &:nth-last-child(2), &:last-child {
      max-width: 70%;
    }
  }
}
