@import "../../styles";

.root {
  position: relative;
  text-align: left;
  background-color: inherit;

  >* {
    display: block;
  }

  p {
    font-size: 14px;
    font-family: Roboto,sans-serif;
    line-height: 1.8;
    margin-bottom: 40px;
  }

  .textField {
    position: relative;
    font-family: Roboto, sans-serif;
    margin-bottom: 25px;
    height: fit-content;

    >label {
      font-size: 16px;
      font-weight: 400;
      position: absolute;
      top: 10px;
      left: 10px;
      transition:0.2s ease all;
      -moz-transition:0.2s ease all;
      -webkit-transition:0.2s ease all;
      pointer-events:none;
      background-color: white;
      padding: 0 6px;
    }

    span {
      display: none;
      position: absolute;
      bottom: -22px;
      left: 16px;
      color: #DF0D27;
      font-size: 12px;
      font-family: Roboto, sans-serif;
    }
  }

  input, textarea {
    border: 1px solid #A3CCFF;
    width: 100%;
    border-radius: 3px;
    background-color: white;
    padding: 10px 24px 10px 14px;
    position: relative;
  }

  >.required:after {
    content: '*';
    position: absolute;
    top: 10px;
    right: 14px;
    color: #DF0D27;
  }

  input:focus, textarea:focus, input:focus, textarea:focus {
    outline: none;
    border-color: $primary2;
    ~ label {
      top:-10px;
      font-size: 14px;
      color: $primary2;
    }
  }

  input {
    height: 42px;
  }

  textarea {
    height: 90px;
    max-height: 130px;
    min-height: 90px;
  }

  .filled {
    ~ label {
      top:-10px;
      font-size: 14px;
    }
  }

  .error {
    margin-bottom: 35px;
    span {
      display: block;
    }

    input, textarea {
      border-color: #DF0D27;
    }

    label {
      color: #DF0D27 !important;
    }
  }

  button {
    background-color: $primary2;
    color: white;
    font-size: 16px;
    font-weight: 700;
    outline: none;
    border-radius: 2px;
    padding: 8px 25px;
    text-transform: none;
    cursor: pointer;
    border: none;
    align-self: flex-start;

    &:hover:not(:disabled) {
      text-decoration: underline;
    }

    &:disabled {
      background-color: lightgrey;
      cursor: unset;
    }
  }

  .successMessage {
    display: none;
    top: -4px;
    bottom: 0;
    left: 0;
    right: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: inherit;
    margin-top: 10%;

    &.visible {
      display: flex;
    }
    img {
      width: 45px;
      height: 45px;
      margin-bottom: 40px;
    }

    p {
      font-size: 18px;
      font-family: Roboto, sans-serif;
      color: $semiblack;
    }

    span {
      text-decoration: underline;
      color: $primary2;
      cursor: pointer;

      &:hover {
        color: $primary1;
      }
    }
  }
}
