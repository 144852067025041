.removalAndRecycling {
  text-align: center;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
  @media (max-width: 992px) {
    padding: 0px 20px 20px;
  }
  h3 {
    font-family: "Titillium Web", sans-serif;
    font-size: 44px;
    font-weight: bold;
    line-height: 1.09;
    text-align: center;
    margin-bottom: 40px;
    color: #323232;
    margin-top: 30px;

    @media (max-width: 992px) {
      font-size: 24px;
      margin-top: 20px;
      margin-bottom: 15px;
      font-weight: bold;
      line-height: 1.33;
      text-align: center;
    }
  }
  h4 {
    font-family: "Titillium Web", sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.09;
    text-align: center;
    margin-bottom: 20px;
    color: #005daa;
    margin-top: 40px;

    @media (max-width: 992px) {
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 10px;
      font-weight: bold;
      line-height: 1.33;
      text-align: center;
    }
  }

  .brands {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    width: 100%;
  }
  p {
    &.subtitle {
      width: 50%;
      margin-top: 20px;
      text-align: center;
      font-family: Roboto, sans-serif;
      font-size: 16px;
      line-height: 1.5;
      color: #323232;
      max-width: 945px;

      @media (max-width: 992px) {
        width: auto;
        margin: 10px 0 20px;
        font-family: Roboto, sans-serif;
        font-size: 14px;
        line-height: 1.43;
        text-align: center;
        color: #323232;
        padding: 0;
      }
    }
  }
  .groupBrands {
    margin-top: 45px;
    max-width: 13%;
    @media (max-width: 992px) {
      margin-top: 17px;
    }
  }
}

@keyframes placeHolderShimmer{
  0%{
      background-position: -880px 0
  }
  100%{
      background-position: 880px 0
  }
}

.animatedBackground {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 880px 495px;
  height: 100%;
  position: relative;
}
