@import "../../common/styles";

.root {
    min-height: 80px;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    z-index: 2;

    @media (max-width: 768px) {
        min-height: 75px;
    }
}
.navigation {
    background-color: $primary1;
    border-bottom-right-radius: 40px;
    display: flex;
    align-items: center;
    flex-grow: 1;

    @media (max-width: 768px) {
        border-bottom-right-radius: 40px;
        flex-direction: column;
        width: 100%;
    }

    @media (min-width: 769px) {
        padding: 13px 0;
    }

    .flexContainer {
        display: flex;
        padding-left: 10%;
        justify-content: space-between;
        align-items: center;
        @media (min-width: 990px) {
            margin-right: 82px;
            min-width: 280px;
        }

        a {
           @media (max-width: 768px) {
                padding-left: 22px;
            }
        }

        @media (max-width: 1600px) {
            padding-left: 10%;
        }

        @media (max-width: 1300px) {
            padding-left: 4%;
        }

        @media (max-width: 768px) {
            padding-left: 0;
            padding-top: 18px;
            padding-right: 0px;
            width: 100%;
        }

        button {
            align-items: center;
            padding-right: 32px;
        }
    }

    .logo {
        margin-right: 6%;

        @media (max-width: 768px) {
            height: 34px;
        }
        @media (min-width: 770px) {
            height: 44px;
            width: 283px;
        }
        @media (min-width: 1440px) {
            height: 48px;
        }
    }
    .burger {
        height: 19px;
        border: none;
        background-color: transparent;
        background-image: none;
        display: none;
        margin-left: 60px;
        outline: none;
        padding: 0;

        span {
            width: 30px;
            height: 3px;
            background-color: white;
            border-radius: 2px;
            display: block;
        }

        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        @media (max-width: 320px) {
            margin-left: 20px;
        }
    }
    .burgerOpened {
        span {
            display: none;
        }
    }
    .burgerOpened::before{
        content: "\00d7";
        color: white;
        font-size: 40px;
        margin-top: -20px;
        margin-right: 6px;
    }
    .menu {
        display: flex;
        align-items: center;
        list-style-type: none;
        padding: 0;
        font-family: Roboto, sans-serif;
        margin: 0;
        transition: max-height 2s cubic-bezier(0, 1, 0, 1);
        -webkit-tap-highlight-color: transparent;

        >li {
            font-size: 18px;
            font-weight: 700;
            color: white;
            cursor: pointer;
            position: relative;
            white-space: nowrap;
            margin-right: 22px;
            flex: 0 0 auto;
            @media (min-width: 768px) {
                padding: 15px;
            }

            a {
                font-family: 'Titillium Web';
                font-size: 18px;
                text-decoration: none;
                color: inherit;
                @media (max-width: 768px) {
                    font-size: 16px;
                }
            }

            span {
                font-family: 'Titillium Web';
                font-size: 18px;
                @media (max-width: 768px) {
                    font-size: 16px;
                }
            }

            .dropdown {
                max-height: 0;
                visibility: hidden;
                list-style-type: none;
                position: absolute;
                left: 0;
                padding: 0;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
                font-weight: 500;
                margin-top: 10px;
                width: 160px;
                overflow: hidden;

                li:not(:last-child) {
                    margin-bottom: 24px;
                }

                @media (max-width: 768px) {
                    position: relative;
                    width: 100%;
                    text-transform: capitalize;
                    background-color: $primary3;
                    padding-left: 30px !important;
                    margin: 0;
                    transition: all .4s ease-in-out;
                }

                @media (min-width: 770px) {
                    background-color: $primary2;
                }

                a {
                    font-family: 'Roboto';
                    font-size: 18px;
                    @media (max-width: 768px) {
                        font-size: 16px;
                        text-transform: capitalize;
                    }
                }
            }

            .dropdownOpened {
                @media (max-width: 768px) {
                    max-height: 1000px;
                    visibility: visible;
                    padding: 16px;
                }
            }

            &.withDropDown {
                @media (min-width: 770px) {
                    &:hover {
                        background-color: $primary2;
                        border-radius: 4px;
                    }
                }
            }

            .spanWithArrow {
                @media (max-width: 765px) {
                    position: relative;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 30px;
                        border-right: 3px solid white;
                        border-bottom: 3px solid white;
                        width: 7px;
                        height: 7px;
                        transform: rotate(-135deg);
                    }
                }
            }

            @media (max-width: 768px) {
                width: 100%;
                text-align: left;
                border-bottom: 1px solid $primary3;
                font-size: 15px;
                margin: 0;
                display: flex;
                text-transform: uppercase;

                &:not(:last-child) {
                    flex-direction: column;
                }

                & > span {
                    padding: 16px 30px;
                }
            }
        }

        >li:hover .dropdown {
            @media (min-width: 770px) {
                padding: 14px;
                max-height: 1000px;
                visibility: visible;
            }
        }

        .phoneNumberForMobile {
            display: none;
        }

        .arrowIcon {
            width: 10px;
            position: absolute;
            top: 50%;
            right: 20px;
        }


        @media (max-width: 768px) {
            flex-direction: column;
            max-height: 0;
            width: 100%;
            text-transform: uppercase;
            overflow: hidden;
            padding-top: 20px;
            will-change: max-height;
            height: fit-content;

            &.opened {
                max-height: 1000px;
                transition: max-height 1s ease-in-out;
            }

            .phoneNumberForMobile {
                display: flex;
                justify-content: space-between;
                border: none;
                padding: 30px 30px;
                text-transform: capitalize;

                > div {
                    display: flex;
                    align-items: center;
                }

                img {
                    height: 36px;
                    margin-right: 10px;
                }
                a {
                    color: white;
                    text-transform: capitalize;
                    font-size: 15px;
                }

                p {
                    margin: 0;
                }

                .phoneNumberContainer{
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}

.rightArea {
    display: flex;
    align-items: center;
    padding-left: 10rem;
    color: white;
    white-space: nowrap;
    padding-right: 7%;
    overflow-x: hidden;

    @media (max-width: 1600px) {
        padding-left: 0;
        margin-left: 6%;
    }

    @media (max-width: 1450px) {
        padding-left: 0;
    }

    @media (max-width: 768px) {
        display: none;
    }

    .phoneNumber {
        font-weight: 700;
        display: flex;
        align-items: center;
        margin-right: 4rem;
        font-size: 18px;

        img {
            height: 36px;
            margin-right: 20px;
        }

        a {
            font-family: 'Titillium Web';
            cursor: pointer;
            padding: 0px;
            font-size: 15px;
        }

        a:hover {
            text-decoration: underline;
        }

        .phoneNumberContainer{
            display: flex;
            flex-direction: column;
        }
    }

    a {
        font-family: 'Titillium Web';
        font-size: 18px;
        text-decoration: none;
        padding-top: 4px;
        color: white;
    }

    a:hover {
        text-decoration: underline;
    }

    .blue {
        color: $primary1;
    }
}
