@import "../../../common/styles";

.root {
  padding-bottom: 80px;
  position: relative;

  h3 {
    font-family: 'Titillium Web', sans-serif;
    font-size: 44px;
    font-weight: bold;
    line-height: 1.09;
    text-align: center;
    color: $semiblack;
    margin-bottom: 14px;
    letter-spacing: 0.6px;
    margin-top: 60px;
    @media (max-width: 992px) {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.33;
      margin: 30px -15px 3px;
      letter-spacing: unset;
      padding: 0;
    }
  }

  .products {
    margin: 0 auto auto;
    text-align: center;

    div {
      margin-top: 30px;
      position: relative;
      img {
        max-width: 100%;
      }
      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 190px;
        margin: 0 auto;
        opacity: 0;
        transition: .5s ease;
        border-radius: 8px;
        background-color: rgba(0, 93, 170, 0.8);

        @media(max-width: 473px) {
          width: 150px;
          height: 100%;
          margin: auto;
        }
      }

      &:hover .overlay {
        opacity: 1;
      }
      .text {
        margin-top: 0;
        color: white;
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
      }
    }

    .image {
      border-radius: 8px;
      height: 190px;
      width: 190px;
      object-fit: contain;

      @media (max-width: 980px) {
        height: 150px;
        width: 150px;
      }
    }
  }

  .rowSafari {
    &:before, &:after {
      display: inline-block !important;
    }
  }
}
