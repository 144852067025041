@import "../../../common/styles";

.locations {
  padding-top: 56px;
  padding-bottom: 0;
  height: auto;
  margin-bottom: 60px;

  @media (max-width: 992px) {
    padding-top: 3px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  h2 {
    font-family: "Titillium Web", sans-serif;
    font-size: 32px;
    margin-top: 32px;
    line-height: 0.75;
    text-align: center;
    color: #ffffff;
    @media (max-width: 992px) {
      font-size: 18px;
      line-height: 1.33;
      padding: 14px;
    }
  }
  h3 {
    margin-top: 2px;
    font-family: "Titillium Web", sans-serif;
    font-size: 44px;
    font-weight: bold;
    line-height: 1.09;
    text-align: center;
    color: #323232;
    margin-bottom: 10px;
    letter-spacing: 0.6px;
    @media (max-width: 992px) {
      font-size: 24px;

      font-weight: bold;
      line-height: 1.33;
      margin-bottom: 16px;
    }
  }
  h4 {
    @media (max-width: 992px) {
      &:after {
        font-family: "Glyphicons Halflings", sans-serif; /* essential for enabling glyphicon */
        float: right;
        /* symbol for "collapsed" panels */
        content: "\e113"; /* adjust as needed, taken from bootstrap.css */
        font-size: 12px;
        padding: 5px;
      }
      &.collapsed:after {
        font-family: "Glyphicons Halflings"; /* essential for enabling glyphicon */
        float: right;
        content: "\e114"; /* adjust as needed, taken from bootstrap.css */
        font-size: 12px;
        padding: 5px;
      }
    }

    width: 200px;
    margin-top: 23px;
    margin-bottom: 22px;
    font-family: Roboto;
    font-size: 18px;
    line-height: 1.33;
    text-align: left;
    color: #005daa;
    padding-bottom: 25px;
    border-bottom: solid 1px #d5d5d5;
    @media (max-width: 992px) {
      width: 100%;
    }
  }

  .sliderMenu {
    padding: 0;
    margin-top: 32px;
    text-align: center;
    @media (max-width: 991px) {
      padding: 13px 0px 11px 7px;
      margin-top: 5px;
      color: #005daa;
      font-family: Roboto, sans-serif;
      font-size: 18px;
      line-height: 1.33;
      text-align: left;
      border-radius: 3px;
      background-color: #f3f3f3;
      border: solid 1px #d5d5d5;
      width: 100%;
    }

    li {
      @media (max-width: 1670px) {
        margin-right: 17px;
        margin-left: 17px;
      }

      @media (max-width: 1468px) {
        margin-bottom: 15px;
      }

      margin-right: 24px;
      margin-left: 35px;
      display: inline-block;
      padding-bottom: 8px;
      font-family: Roboto, sans-serif;
      font-size: 18px;
      line-height: 1.33;
      text-align: center;
      color: #8b8a96;
      text-transform: none;
      white-space: nowrap;
      cursor: pointer;

      &.underline {
        border-bottom: solid #2481cd 3px;
        color: #2481cd;
        a,
        a:visited {
          color: #2481cd;
        }
      }
      a,
      a:visited {
        font-family: Roboto;
        font-size: 18px;
        line-height: 1.33;
        text-align: center;
        color: #8b8a96;
        text-transform: none;
      }
      a:hover,
      a:active,
      a:focus {
        font-family: Roboto;
        font-size: 18px;
        line-height: 1.33;
        text-align: center;
        color: #005daa;
        text-decoration: none;
      }
    }
  }
  .slider {
    margin-top: 0px;
    @media (max-width: 992px) {
      margin-top: 9px;
    }
  }

  .locationNavigation {
    margin-bottom: 20px;

    @media (max-width: 980px) {
      padding: 0 8px;
    }
  }

  .locationSelector {
    width: 100%;
    height: 50px;
    outline: none;
    background-color: #f3f3f3;
    color: $primary1;
    font-size: 18px;
    font-weight: 400;
    border: 1px solid #d5d5d5;
    border-radius: 3px;
    padding: 8px 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
  }
}

.map {
  height: 720px;
  width: 100%;
  padding: 0 10px;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 0 8px;
  border-color: #8b8a96 transparent transparent transparent;
  position: absolute;
  top: 22px;
  right: 25px;
}
