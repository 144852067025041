@import "../../../../common/styles";

.memberImage {
  margin-top: 45px;
  border-radius: 50%;
  width: 170px;
}

.subtitle {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: $semiblack;
  padding: 27px 0 0 0;
  width: 101%;

  @media (max-width: 980px) {
    margin-top: 5px;
    margin-bottom: 20px;
  }
  @media (min-width: 981px) {
    max-width: 85%;
  }
}

.description {
  padding: 0 40px;

  @media (max-width: 980px) {
    font-size: 14px;
    padding: 0;
  }
}
