.familyBrand {
  text-align: center;
  padding-bottom: 80px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 992px) {
    padding: 24px 20px 20px;
  }
  h3 {
    font-family: "Titillium Web", sans-serif;
    font-size: 44px;
    font-weight: bold;
    line-height: 1.09;
    text-align: center;
    margin-bottom: 80px;
    color: #323232;
    margin-top: 78px;

    @media (max-width: 992px) {
      font-size: 24px;
      margin-top: 40px;
      margin-bottom: 30px;
      font-weight: bold;
      line-height: 1.33;
      text-align: center;
    }
  }
  .brands {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    width: 100%;
  }
  p {
    &.subtitle {
      width: 50%;
      margin-top: 83px;
      text-align: center;
      font-family: Roboto, sans-serif;
      font-size: 16px;
      line-height: 1.5;
      color: #323232;

      @media (max-width: 992px) {
        width: auto;
        margin: 50px 0 46px;
        font-family: Roboto, sans-serif;
        font-size: 14px;
        line-height: 1.43;
        text-align: center;
        color: #323232;
        padding: 0;
      }
    }
  }
  .groupBrands {
    margin-top: 45px;
    max-width: 13%;
    @media (max-width: 992px) {
      margin-top: 17px;
    }
  }
}
