.warehouseService {
  padding-top: 27px;
  @media (max-width: 992px) {
    padding: 8px;
  }
  h3 {
    padding-top: 5px;
    font-family: "Titillium Web", sans-serif;
    font-size: 44px;
    font-weight: bold;
    line-height: 1.09;
    text-align: center;
    color: #323232;
    margin-bottom: 14px;
    margin-top: 20px;
    letter-spacing: 0.6px;
    @media (max-width: 992px) {
      margin-top: 28px;
      margin-left: 2px;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.33;
    }
  }
  .services {
    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }
  .servicesRow {
    margin-bottom: 40px;
    flex-wrap: wrap;
    p {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      white-space: pre-line;

      @media (max-width: 980px) {
        max-width: 60%;
      }
    }
    @media (min-width: 992px) {
      margin-top: 24px;

      div {
        padding-left: 10px;
        float: left;
      }
    }

    @media (max-width: 991px) {
      margin-bottom: 30px;
    }

    .serviceDescription {
      @media (min-width: 992px) {
        max-width: 25%;
        min-width: 200px;

        &:nth-child(1) {
          @media (max-width: 1400px) {
            p {
              width: 90%;
            }
          }
        }

        &:nth-child(3) {
          @media (max-width: 1400px) {
            p {
              width: 98%;
            }
          }
        }

        &:last-child {
          max-width: 20%;
        }
      }
      @media (max-width: 992px) {
        min-width: 100%;
      }
    }
  }
}
