.main {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}


#zuora_payment {
    width: 100%;
    margin-top: 80px;
    height: auto;
    /*min-height: 100vh;*/
}

iframe {
    width: 100vw;
    height: 150vh;
    height: 1200px !important;
    padding-block: 3rem !important;
    overflow-y: scroll;
}
@media only screen and (min-width: 820px){
    iframe {
        height: 110vh !important;
    }
}

@media only screen and (min-width: 1023px){
    iframe {
        height: 120vh !important;
    }
}

@media only screen and (min-width: 1400px){
    iframe {
        height: 140vh !important;
    }
}
