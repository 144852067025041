@import "../../../common/styles";

.root {
  background-color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: "Titillium Web", sans-serif;
  padding: 50px 10% 88px;

  @media (max-width: 768px) {
    padding: 4px 14px 14px;
  }

  h2 {
    font-size: 44px;
    font-weight: 700;
    letter-spacing: 0.6px;
    color: #323232;
    margin-bottom: 14px;
    font-family: "Titillium Web", sans-serif;

    @media (max-width: 768px) {
      font-size: 24px;
      margin-top: 20px;
    }
  }

  p {
    font-family: Roboto, sans-serif;
    font-size: 20px;
    letter-spacing: .1px;
    color: #575563;
    margin-top: 0;
    margin-bottom: 70px;

    @media (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  .brands {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 38px;

    @media (max-width: 768px) {
      justify-content: space-around;
      flex-wrap: wrap;
    }

    img {
      margin-bottom: 20px;

      @media (max-width: 768px) {
        max-width: 150px;
        margin: 20px 20px 20px 0;
        max-height: 40px;
      }
    }

    >a {
      @media (max-width: 768px) {
        max-width: 150px;
        max-height: 40px;
        margin: 20px 20px 20px 0;
      }
    }
  }

  .wideBrands {
    margin-bottom: 0;

    @media (min-width: 1200px) {
      padding-left: 35px;
    }
    img {
      margin-bottom: 0;
      @media (max-width: 768px) {
        max-width: 40%;
        min-width: 30%;
        margin: 20px 20px 20px 0;
        max-height: 50px;

        &:last-child {
          max-width: 70%;
        }
      }
    }
  }

  .brandsMobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    > img {
      max-width: 40%;
      margin: 16px;

      &:last-child {
        max-width: 70%;
      }
    }
  }
}

