$primary1: #005daa;
$primary2: #3477C5;
$primary3: #2481CD;
$semiblack: #323232;

* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}

html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
  font-family: 'Roboto', sans-serif;
  overflow: auto;
  height: 100vh;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

body {
  min-width: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
