@import '../../../../common/styles';
.card {
  background-color: white;
  height: 300px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 55px 0px 36px;
  width: 32%;
  font-family: Roboto, sans-serif;
  position: relative;
  margin: 18px 14px;
  flex: 2 0 26%;
  max-width: 30%;

  @media (max-width: 980px) {
    max-width: 100%;
    flex: 0 auto;
    width: 100%;
    margin: 9px 0px;
    justify-content: space-between;
    padding: 45px 0px 38px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  > .cardIcon {
    height: 40%;
    margin-bottom: 40px;
    z-index: 2;

    @media (max-width: 980px) {
      margin: 0;
    }
  }

  > p {
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 26px;
    margin-top: 0;
    margin-bottom: 0;
    z-index: 2;
    width: 90%;
    padding: 0 14px;

    @media (max-width: 980px) {
      margin: 0;
      width: 100% !important;
    }
  }

  .shadowedIcon {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 1;

    @media (max-width: 980px) {
      opacity: 0.8;
    }
  }

  &.cardWithSpan {
    padding-top: 45px;
    .cardIcon {
      margin-bottom: 25px;
      height: 55%;

      @media (max-width: 1500px) {
        margin-bottom: 15px;
      }
    }
    > span {
      font-weight: 700;
      font-size: 20px;
      color: $primary1;
      z-index: 2;
      margin-bottom: 10px;
    }
    > p {
      font-size: 18px;
    }
  }
}
