@import "../../styles";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 250px 80px;
  background-color: white;

  @media (max-width: 1440px) {
    padding: 80px 80px 120px;
    text-align: center;
  }

  @media (max-width: 768px) {
    padding: 44px 12px 36px;
    text-align: center;
    border-bottom: 1px solid #D5D5D5;
  }

  h2 {
    font-size: 44px;
    font-weight: 700;
    letter-spacing: .7px;
    line-height: 1.09;
    color: $semiblack;
    font-family: "Titillium Web", sans-serif;
    margin-top: 0;
    margin-bottom: 60px;

    @media (max-width: 768px) {
      font-size: 22px;
      letter-spacing: .7px;
      margin-bottom: 30px;
    }
  }
}

.content {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  img {
    @media (max-width: 768px) {
      max-width: 70%;
      margin-bottom: 25px;
    }
  }
}

.scoreInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 77px;

  @media (max-width: 768px) {
    padding-left: 0;
  }

  h3 {
    color: $primary1;
    font-family: "Titillium Web", sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 35.2px;
    margin-bottom: 20px;
    margin-top: 0;

    @media (max-width: 768px) {
      font-size: 26px;
      line-height: 28.6px;
      margin-bottom: 7px;
    }
  }

  .disclaimer {
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #575563;
    font-weight: 400;
    margin-bottom: 60px;

    @media (max-width: 768px) {
      margin-bottom: 0;
      max-width: 85%;
      align-self: center;
    }
  }

  .descriptionButton {
    text-decoration: none;
    color: $primary2;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    border-bottom: 1px solid #D5D5D5;
    align-self: flex-start;
    cursor: pointer;
    position: relative;

    &:hover {
      color: #23527C;
    }

    @media (max-width: 768px) {
      font-size: 14px;
      letter-spacing: .9px;
      align-self: center;
    }
  }
}
