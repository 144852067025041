@import "../../common/styles";

.root {
  width: 100%;
  overflow: hidden;

  .mainPicture {
    height: 491px;
    text-align: center;
    padding-top: 224px;
    background-position: center top;
    background-size: cover;
    width: 100%;

    @media (max-width: 768px) {
      height: 420px;
      padding-top: 134px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .title {
    color: white;
    font-size: 48px;
    font-weight: 700;
    font-family: "Titillium Web", sans-serif;
    letter-spacing: 1px;
    margin-bottom: 18px;

    @media (max-width: 768px) {
      font-size: 36px;
      letter-spacing: .7px;
    }

    @media (min-width: 1450px) {
      font-size: 52px;
    }
  }

  .subtitle {
    color: white;
    font-size: 32px;
    font-weight: 500;
    font-family: "Titillium Web", sans-serif;
    letter-spacing: .7px;
    line-height: .75;
    margin-top: 18px;

    @media (max-width: 768px) {
      font-size: 18px;
      margin-top: 24px;
      line-height: unset;
      max-width: 90%;
    }

    @media (min-width: 1450px) {
      font-size: 34px;
    }
  }

  .description {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    min-height: 452px;
    line-height: 1.5;
    font-weight: 400;
    text-align: left;
    color: $semiblack;
    background-color: #ebebeb;
    margin-bottom: 6px;
    padding-top: 60px;
    position: relative;

    @media (max-width: 992px) {
      padding-top: 17px;
      max-height: 476px;
      overflow: hidden;
      padding-bottom: 0;
      font-size: 16px;

      &.expanded {
        max-height: none;
      }
    }

    @media (min-width: 1500px) {
      font-size: 18px;
    }

    p {
      margin-bottom: 24px;
      @media (max-width: 992px) {
        max-width: 90%;
      }
    }

    .paragraph1 {
      padding-left: 159px;
      padding-right: 45px;

      @media (max-width: 992px) {
        padding: 19px 19px 0;
        text-align: center;
        margin: 0;
      }
    }
    .paragraph2 {
      padding-left: 19px;
      padding-right: 157px;

      @media (max-width: 992px) {
        padding: 0 19px 19px;
        text-align: center;
      }
    }
  }
}
