@import "../../../common/styles";

.root {
  background: #a3ccff;
  position: relative;
}

.content {
  @media(max-width: 992px) {
    padding-bottom: 35px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .map {
    margin-top: 54px;
    margin-bottom: 51px;
    margin-left: -15px;
    width:100%;

    @media(max-width: 992px) {
      margin: 30px auto 0;
    }
  }
}

h3 {
  font-family: 'Titillium Web', sans-serif;
  margin-top: 0;
  font-size: 44px;
  font-weight: bold;
  line-height: 1.09;
  text-align: left;
  color: $semiblack;
  @media (max-width: 992px) {
    margin-top: 27px;
    letter-spacing: .05px;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.09;
    text-align: center;
    padding: 0 30px;
  }
}

p.subtitle {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 1.56;
  text-align: left;
  color: $semiblack;
  margin-bottom: 32px;
  margin-top: 34px;
  max-width: 90%;
  @media (max-width: 992px) {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 23px;
    letter-spacing: -0.1px;
    padding: 0 40px;
    max-width: 100%;
    font-size: 15px;
    line-height: 1.47;

  }
}
p.caption {
  padding-right: 152px;
  text-align: left;
  padding-top: 7px;
  font-size: 14px;
  margin-bottom: 0;

  @media (max-width: 992px) {
    text-align: center;
    padding: 2px 15px;

    line-height: 1.57;
  }

  @media (min-width: 1450px) {
    font-size: 16px;
  }
}

.form {
  padding-left: 52px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 992px) {
    padding-left: 10px;
    margin-top: 25px;
  }
}
