@import "../../../common/styles";

.root {
  background-color: #EBEBEB;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 61px 14% 86px;

  @media (max-width: 1440px) {
    padding: 61px 10% 86px;
  }

  @media (max-width: 768px) {
    padding: 33px 30px 0;
  }

  h2 {
    font-size: 44px;
    font-weight: 700;
    letter-spacing: .6px;
    color: $semiblack;
    font-family: "Titillium Web", sans-serif;
    margin-top: 0;
    margin-bottom: 42px;

    @media (max-width: 768px) {
      font-size: 24px;
      letter-spacing: .7px;
      margin-bottom: 22px;
    }
  }

  .subtitle {
    font-size: 16px;
    font-family: Roboto, sans-serif;
    line-height: 28px;
    color: $primary1;
    margin-bottom: 60px;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 21.98px;
      margin-bottom: 33px;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    section {
      width: 50%;
      display: flex;
      color: $semiblack;
      font-family: Roboto, sans-serif;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
      }

      img {
        align-self: flex-start;
        margin-right: 33px;
        width: 60px;

        @media (max-width: 768px) {
          align-self: center;
          margin-right: 0;
          margin-bottom: 30px;
        }
      }

      >div {
        text-align: left;
        display: flex;
        flex-direction: column;

        @media (max-width: 768px) {
          text-align: center;
          margin-bottom: 30px;
        }

        span {
          font-size: 18px;
          font-weight: 500;
          line-height: 23.94px;
          margin-bottom: 15px;

          @media (max-width: 768px) {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }

        p {
          font-size: 16px;
          line-height: 28px;
          margin: 0;

          @media (max-width: 768px) {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }

    >:first-child {
      width: 42%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}
