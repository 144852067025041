@import "../../common/styles";

.title {
  padding-top: 166px;

  @media(max-width: 992px) {
    padding-top: 95px;
    text-align: center;
  }

  h1 {
    font-family: 'Titillium Web', sans-serif;
    font-size: 48px;
    font-weight: bold;
    line-height: 1.13;
    text-align: center;
    color: #323232;
    margin-bottom: 40px;
    @media (max-width: 992px) {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.33;
      text-align: center;
      margin-bottom: 0;
      color: #323232;
    }
  }
}

.content {
  padding-bottom: 60px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #323232;

  @media (max-width: 992px) {
    padding: 18px;
  }

  li {
    >p {
      margin-bottom: 0;
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  * {
    font-family: Roboto, sans-serif;
    color: $semiblack;
  }

  a {
    text-decoration: none;
    color: #337AB7;

    &:hover {
      text-decoration: underline;
      color: #23527C;
    }
  }
}
