@import "../../../../common/styles";

.card {
  height: 255px;
  width: 30%;
  padding: 31px 24px;
  margin: 0 25px;
  position: relative;
  border-radius: 8px;
  cursor: pointer;

  @media (min-width: 1500px) {
    padding: 31px 37px;
  }

  @media (max-width: 980px) {
    width: 100%;
    border-radius: 0;
    height: 148px;
    margin: 0;
  }

  >img {
    height: 67px;
    align-self: start;
    margin-bottom: 36px;

    @media (max-width: 980px) {
      display: none;
    }
  }

  .cardCorner {
    border-bottom-left-radius: 8px;
    width: 31px;
    height: 31px;
    position: absolute;
    top: 0;
    right: 0;

    @media (max-width: 980px) {
      display: none;
    }
  }

  .header {
    font-size: 24px;
    font-weight: 700;
    color: white;
    font-family: "Titillium Web", sans-serif;
    letter-spacing: .4px;
    margin-top: 0;
    margin-bottom: 17px;

    @media (min-width: 1500px) {
      margin-bottom: 30px;
    }

    @media (max-width: 980px) {
      text-align: left;
      margin-bottom: 20px;
      padding-left: 0;
    }
  }

  .cardText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 12px;

    @media (max-width: 980px) {
      text-align: left;
    }

    img {
      height: 31px;
    }

    .cardDescription {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        color: white;
        font-size: 15px;
        margin: 0;
        white-space: break-spaces;
        max-width: 80%;

        @media (max-width: 1300px) {
          max-width: 90%;
        }

        @media (max-width: 675px) {
          max-width: 80%;
        }
      }
    }
  }

  &:nth-child(1), &:nth-child(2) {
    .cardDescription p {
      @media (min-width: 1400px) {
        max-width: 85%;
      }

      @media (min-width: 1800px) {
        max-width: 60%;
      }
    }
  }

  &:nth-child(3) {
    .cardDescription p {
      @media (max-width: 1390px) {
        max-width: 85%;
      }

      @media (min-width: 1800px) {
        max-width: 55%;
      }
    }
  }
}
