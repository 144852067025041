@import "../../../common/styles";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 14% 40px;
  color: $semiblack;

  @media (max-width: 1450px) {
    padding: 20px 8% 40px;
  }

  @media (max-width: 768px) {
    padding: 20px;
  }

  h2 {
    font-size: 44px;
    font-weight: 700;
    letter-spacing: .6px;
    color: $semiblack;
    font-family: "Titillium Web", sans-serif;
    margin-top: 0;
    margin-bottom: 50px;

    @media (max-width: 768px) {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }

  h3{
    color: #4C9FE3;
    font-family: Titillium Web;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 109.091% */
    margin-top: 5px;
    margin-bottom: 20px;
  }

  h4 {
    font-family: "Titillium Web", sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.09;
    text-align: center;
    margin-bottom: 20px;
    color: #005daa;
    margin-top: 0px;

    @media (max-width: 992px) {
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 10px;
      font-weight: bold;
      line-height: 1.33;
      text-align: center;
    }
  }

  .content {
    display: flex;
    align-items: flex-start;
    padding: 0 10px;
    margin-top: 20px;
    max-width: 1280px;

    @media (max-width: 1450px) {
      padding: 0;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 30px;
      margin-top: 20px;
    }

    img {
      margin-right: 60px;
      flex-shrink: 0;

      @media (max-width: 768px) {
        margin: 0 20px 32px 20px;
        width:100%
      }
    }

    p {
      font-family: Roboto, sans-serif;
      font-size: 16px;
      line-height: 26.08px;
      flex-grow: 1;
      margin: 0;
      max-width: 945px;
      color: #fff;

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 1.59;
      }

      a{
        color:#005DAA;
      }
    }
  }

  .title{
    font-weight: bold;
    color:#4C9FE3;
  }

  .subtitle{
    color:#fff;
  }

  .footText {
    color: $primary1;
    font-size: 18px;
    font-weight: 500;
    line-height: 28.08px;
    font-family: Roboto, sans-serif;
    text-align: center;
    -webkit-text-size-adjust: 100%;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
    }
  }

  /* Container for flexboxes */
  .row {
    display: flex;
    flex-wrap: wrap;
    max-width: 1450px;
  }

  /* Create four equal columns */
  .column {
    flex: 33%;
    padding: 20px 60px;
    text-align: center;
  }

  /* On screens that are 992px wide or less, go from four columns to two columns */
  @media screen and (max-width: 992px) {
    .column {
      padding: 10px 30px;
      flex: 50%;
    }

  /* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .row {
      flex-direction: column;
      padding: 10px 30px;
    }
  }
  }

  .logo_container{
    height: 100px;
    margin: 0;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .logo{
    max-width:190px;
    max-height:100px;

  }

  .cta{
    width:100%;
    text-align: center;

    .button{
      border-radius: 6px;
      background: #005DAA;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      display: inline-flex;
      padding: 12px 30px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #fff;
      text-decoration: none;
    }
  }
  
}
