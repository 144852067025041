@import "../../../common/styles";

.root {
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
}

.title {
  padding-top: 139px;
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 980px) {
    margin-top: 40px;
    padding-top: 99px;
  }

  h1 {
    font-family: "Titillium Web", sans-serif;
    font-size: 48px;
    font-weight: bold;
    line-height: 1.13;
    text-align: center;
    color: #323232;
    letter-spacing: 0.7px;
    margin-bottom: 10px;
    @media (max-width: 992px) {
      font-size: 35px;
      line-height: 1.15;
      margin-bottom: 0;
    }
  }

  p {
    font-family: Roboto, sans-serif;
    position: relative;
    top: -5px;
    left: -3px;
    font-size: 18px;
    line-height: 1.56;
    text-align: center;
    color: #323232;
    padding: 0 50px;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 16px;
      padding: 0 30px;
    }

    a {
      text-transform: none;
    }
  }
  .titleText {
    white-space: pre-line;
    text-align: center;
    @media (max-width: 992px) {
      font-family: Roboto, sans-serif;
      position: relative;
      top: -1px;
      left: -1px;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      color: #323232;
      padding: 0 15px;
      margin-bottom: 84px;
    }
    a {
      color: #337ab7;
      text-decoration: none;
    }
  }
}

.aboutText {
  @media (min-width: 992px) {
    display: flex;
  }
  margin-top: 2px;
  margin-bottom: 6px;
  padding: 45px 10% 26px 7%;
  position: relative;

  @media (max-width: 992px) {
    padding: 37px 30px 0;
  }

  .year {
    font-family: Roboto, sans-serif;
    font-size: 30px;
    font-weight: bold;
    line-height: 0.8;
    text-align: left;
    color: #323232;

    &.second {
      padding-left: 8px;
    }
    @media (max-width: 992px) {
      text-align: center;
      margin: 0;
      &.second {
        padding-left: 16px;
      }
    }
  }
  p {
    letter-spacing: 0;
    padding: 0 11px 0 9px;
    margin-bottom: 30px;
    margin-top: -5px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.58;
    text-align: left;
    color: #323232;

    @media (max-width: 992px) {
      padding-top: 15px;
      text-align: center;
      margin-bottom: 29px;
      margin-top: 3px;
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.dateWrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 992px) {
    display: flex;
    width: 100%;
  }
}

.dateItem {
  @media (min-width: 992px) {
    display: inline-block;
  }
}
.historyText {
  break-inside: avoid;
  p {
    break-inside: avoid;
  }
}
