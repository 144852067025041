@import "../../common/styles";

.head {
  .title {
    padding-top: 166px;

    @media(max-width: 992px) {
      padding-top: 115px;
      text-align: center;
    }

    h1 {
      font-family: 'Titillium Web', sans-serif;
      font-size: 48px;
      font-weight: bold;
      line-height: 1.13;
      text-align: center;
      color: $semiblack;
      @media (max-width: 992px) {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.33;
        text-align: center;
        color: $semiblack;
      }
    }

    p {
      font-family: Roboto, sans-serif;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      color: $semiblack;
      padding: 23px 20px 0;
      @media (max-width: 992px) {
        padding: 0 20px 0;
      }

      a {
        text-transform: none;
      }
    }
    @media (max-width: 992px) {
      .title-text {
        font-family: Roboto, sans-serif;
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        color: $semiblack;
        padding: 0 15px;
        margin-bottom: 84px;
      }
    }
  }
}

.contact {
  text-align: left;
  padding-top: 34px;
  padding-bottom: 91px;

  @media (max-width: 992px) {
    padding-bottom: 40px;
    padding-top: 6px;
  }

  .formContainer {
    padding: 20px 100px 20px 20px;
    height: 100%;

    @media (max-width: 992px) {
      padding: 26px;
    }
  }

  .mapContainer {
    @media (max-width: 992px) {
      padding: 26px;
    }
  }

  .map {
    height: 320px;
    width: 100%;
  }

  .address {
    font-size: 16px;
    color: $semiblack;
    display: flex;
    align-items: center;

    img {
      margin-right: 12px;
    }
  }
}
