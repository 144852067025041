@import "../../../common/styles";

.root {
    background-color: #EBEBEB;
    display: flex;
    flex-direction: column;
    font-family: Roboto, sans-serif;
    padding: 60px 30px 40px;

    @media (min-width: 981px) {
        display: none;
    }

    .linksContainer {
        columns: 2;
    }

    section {
        display: inline-block;
        flex-direction: column;
        width: 100%;

        h4 {
            margin: 0 0 28px;
            font-size: 16px;
            font-weight: 700;
            font-family: Roboto, sans-serif;
            color: $semiblack;
            text-decoration: none;
            text-transform: capitalize;
        }

        >div {
            display: flex;
            flex-direction: column;
            >a {
                font-size: 14px;
                font-weight: 400;
                color: $semiblack;
                cursor: pointer;
                margin-bottom: 4px;
                text-decoration: none;
                text-transform: capitalize;
                display: inline-block;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .terms {
        display: flex;
        >div {
            flex-direction: row;
        }

        >span {
            margin: 0 0 14px;
            font-size: 14px;
        }

        a {
            text-transform: capitalize;
            font-size: 14px;
            font-family: Roboto, sans-serif;
            text-decoration: none;
            margin-right: 12px;
        }
    }

    .blueLink {
        color: $primary1;
    }

    .noLink {
        cursor: default;
        text-decoration: none !important;
    }
}


