@import "../../../common/styles";

.root {
  text-align: center;
  padding-top: 25px;
  padding-bottom: 121px;
  @media (max-width: 992px) {
    padding-top: 0px;
    padding-bottom: 41px;
  }
  h3 {
    font-family: "Titillium Web", sans-serif;
    font-size: 36px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: $semiblack;
    margin-top: 45px;
    margin-bottom: 0;
    letter-spacing: 0.6px;
    @media (max-width: 992px) {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.33;
      margin-bottom: 0px;
      margin-top: 45px;
    }
  }

  .team {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 6px 54px 0 54px;
  }
}
