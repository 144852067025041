@import "../../../common/styles";

.main{
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary1;
  font-size: 44px;
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 25px;

  @media (max-width: 980px) {
    flex-direction: column;
    font-size: 28px;
    margin-bottom: 28px;
    flex: 0 0 auto
  }

  img {
    height: 96px;
    margin-bottom: 25px;
    margin-left: 23px;

    @media (max-width: 980px) {
      height: 83px;
      margin: 8px 0 0;
    }
  }
}

.carousel{
  padding: 20px;
  text-align: left;

  .imageContainer{
    position: relative;
    color: white;

    .image{
      width:50%;
      height:100%;
    }

    .date{
      color: #FFF;
      text-align: center;
      font-family: Roboto;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      position: absolute;
      top: 50%;
      left: 25%;
      transform: translate(-50%, -50%);
      background: rgba(0, 0, 0, 0.250);
      height: 100%;
      width: 50%;
      border-radius: 50%;
      padding-top: 20%;

      @media (max-width: 1024px) {
        padding-top: 18%;
      }
  
      @media (max-width: 464px) {
        padding-top: 20%;
      }
    }

    .line{
      position: absolute;
      height: 1px;
      background: #C0BFC7;
      width: 130%;
      z-index: -1;
      top:50%;
    }
  }

  p{
    color: var(--charcoal, #323232);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin-top: 25px;
  }

}

.root {
  max-width: 1600px;
  margin: 0 auto;
  text-align: center;
  padding: 200px 0 40px;

  @media (max-width: 1024px) {
    padding: 40px 0;
  }

  .subtitle {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: $semiblack;
    letter-spacing: .01px;
    font-family: Roboto, sans-serif;
    width: 75%;
    margin: 0 auto 40px;

    @media (max-width: 980px) {
      width: 100%;
      letter-spacing: 0.01px;
      line-height: 24px;
      font-weight: 400;
      margin: 0 0 40px;
      padding:0 20px;
    }
  }

  .learnMore {
    align-items: center;
    background-color: white;
    border: 1px solid $primary3;
    color: $primary1;
    text-decoration: none;
    font-size: 16px;
    height: 43px;
    font-weight: 700;
    font-family: Roboto, sans-serif;
    align-self: center;
    padding: 12px 25px;
    margin-top: 54px;

    &:hover {
      text-decoration: underline;
    }

    @media (max-width: 980px) {
      margin-top: -10px;
    }
  }
}


.carouselButton{
  background: #FFF;
  font-size:14px;
  position: absolute;
  outline: 0;
  transition: all .5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  opacity: 1;
  cursor: pointer;
  padding: 0;
}

.buttonRight{
  right: calc(4% + 1px);
}

.buttonLeft{
  left: calc(4% + 1px);
}