.root {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, #EBEBEB 100px, rgba(255, 255, 255, 0));
  justify-content: center;
  align-items: flex-end;
  height: 200px;
  display: none;

  button {
    background-color: white;
    border: 1px solid #D5D5D5;
    color: #7A8590;
    cursor: pointer;
    outline: none;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    height: 40px;
    width: 132px;
    margin-bottom: 40px;
    -webkit-tap-highlight-color: transparent;
  }

  @media (max-width: 768px) {
    display: flex;
  }
}
