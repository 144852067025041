.ourHeroes {
  position: relative;
  h3 {
    margin-top: 20px;
    margin-bottom: 7px;
    font-family: "Titillium Web", sans-serif;
    font-size: 36px;
    font-weight: bold;
    line-height: 1.33;
    text-align: left;
    color: #323232;
    @media (max-width: 992px) {
      font-size: 24px;
      font-weight: bold;
      line-height: 2;
      text-align: center;
      margin-top: 0;
      color: #323232;
    }
  }
  .heroesContent {
    background-color: #ebebeb;
    text-align: right;
    padding-top: 76px;
    padding-bottom: 86px;
    @media (max-width: 992px) {
      text-align: center;
      padding-top: 30px;
      padding-bottom: 28px;
      margin-top: 0;
    }
    .mobileImageContainer {
      padding: 0 25px;

      img {
        width: 100%;
      }
    }
    .heroesText {
      margin-top: 24px;
      padding-left: 42px;
      padding-right: 89px;
      background-color: #ebebeb;
      @media (max-width: 1450px) {
        padding-right: 0;
      }
      @media (max-width: 991px) {
        padding-left: 0;
        margin-top: 0;
      }
    }
    p {
      font-family: Roboto, sans-serif;
      font-size: 16px;
      line-height: 1.5;
      text-align: left;
      color: #323232;
      padding-top: 15px;
      max-width: 98%;
      @media (max-width: 992px) {
        margin-top: 13px;
        font-family: Roboto, sans-serif;
        font-size: 14px;
        line-height: 1.34;
        text-align: center;
        color: #323232;
        padding: 4px 35px;
        max-width: 100%;
      }
    }
    .hero1 {
      padding-bottom: 25px;
    }
    .hero3 {
      padding-left: 26px;
      float: right;
    }
  }
}
