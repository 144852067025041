@import "../../../common/styles";

.root {
  background-color: #A3CCFF;
  display: flex;
  padding: 80px 120px;
  color: $semiblack;
  align-items: center;
  position: relative;
  height: 644px;

  @media (min-width: 1550px) {
    height: auto;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 30px 20px 17px;
    height: auto;
    margin-top: 29px;
  }

  .map {
    width: 46%;

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 21px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 8%;

    @media (max-width: 768px) {
      padding: 0;
      align-items: center;
      text-align: center;
    }

    h2 {
      font-family: "Titillium Web", sans-serif;
      font-weight: 700;
      font-size: 44px;
      margin-bottom: 40px;
      letter-spacing: .7px;

      @media (max-width: 768px) {
        font-size: 22px;
        margin-bottom: 31px;
      }
    }

    p {
      font-family: Roboto, sans-serif;
      font-size: 18px;
      font-style: normal;
      line-height: 28.08px;
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 32px;
      max-width: 80%;

      @media (max-width: 1450px) {
        max-width: 100%;
      }

      @media (max-width: 768px) {
        font-size: 15px;
        line-height: 1.47;
        letter-spacing: -.1px;
        width: 90%;
        max-width: none;
        margin-bottom: 25px;
      }
    }

    span {
      font-size: 14px;
      line-height: 20px;
      max-width: 80%;
      font-weight: 400;

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 1.57;
        max-width: none;
      }
    }
  }
}
