@import "../../common/styles";

.root {
  width: 100%;
  overflow: hidden;
}

.main {
  width: 100%;
  height: 800px;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media(max-width: 992px) {
    background-position: center;
    padding-top: 120px;
    height: 615px;
    text-align: center;
  }

  h1 {
    font-family: 'Titillium Web', sans-serif;
    font-size: 48px;

    font-weight: bold;
    line-height: 1.13;
    text-align: center;
    color: $primary1;
    margin-bottom: 37px;

    @media (max-width: 992px) {
      font-size: 35px;
      line-height: 1.15;
    }
  }
}
