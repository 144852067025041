@import "../../common/styles";

.head {
  .title {
    padding-top: 230px;
    height: 540px;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;

    @media(max-width: 992px) {
      padding-top: 158px;
      height:389px;
      text-align: center;
    }

    h1 {
      font-family: 'Titillium Web', sans-serif;
      font-size: 48px;

      font-weight: bold;
      line-height: 1.13;
      text-align: center;
      color: #ffffff;
      margin-bottom: 37px;

      @media (max-width: 992px) {
        font-size: 35px;
        line-height: 1.15;
      }
    }
  }

  .topText {
    padding: 63px;
    text-align: center;
    background-color: #ebebeb;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: $semiblack;
    @media(max-width:992px){
      padding: 37px 9px 30px;
    }
    @media(min-width: 1500px){
      font-size: 18px;
    }
    p.subtitle{
      font-weight:bold;
      @media (max-width: 992px) {
        max-width: 90%;
      }
      @media (max-width: 376px) {
        max-width: 95%;
      }
    }
    p.titleText{
      padding: 0 10px;
      margin-left: -21px;
      margin-right: -21px;
      margin-top: 23px;
      white-space: pre-line;

      @media (max-width: 992px) {
        font-family: Roboto, sans-serif;
        line-height: 1.5;
        text-align: center;
        color: $semiblack;
        padding: 0 15px;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
