@import "../../common/styles";

.lineBreak {
  display: none;
  @media (max-width: 768px) {
    color: #D5D5D5;
    margin: 0;
    display: block;
  }
}

.title {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 246px;
  height: 493px;

  @media (max-width: 992px) {
    padding-top: 125px;
    height: 390px;
    text-align: center;
  }

  h1 {
    font-family: "Titillium Web", sans-serif;
    font-size: 48px;
    font-weight: bold;
    line-height: 1.13;
    text-align: center;
    color: #ffffff;
    letter-spacing: 0.7px;
    margin-bottom: 37px;
    @media (max-width: 992px) {
      font-size: 35px;
      line-height: 1.15;
      padding: 12px 30px;
    }
  }
}
.topText {
  text-align: center;
  background-color: #ebebeb;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  padding: 46px 52px 62px;
  color: $semiblack;
  @media (min-width: 1500px) {
    font-size: 18px;
  }
  .paragraphContainer {
    display: flex;
    justify-content: center;
  }
  p.subtitle {
    font-weight: bold;
  }
  p.titleText {
    margin-left: -16px;
    margin-right: -15px;
    margin-top: 23px;
    white-space: pre-line;
    @media (max-width: 992px) {
      margin: 0;
    }
  }
  @media (max-width: 992px) {
    padding: 38px 22px 24px;
  }
}
