@import "../../../common/styles";

.root {
  padding-top: 34px;
  padding-bottom: 25px;
  @media (max-width: 992px) {
    padding-top: 0;
    padding-bottom: 6px;
  }

  .head {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h3 {
    font-family: 'Titillium Web', sans-serif;
    font-size: 36px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: $semiblack;
    margin-bottom: 7px;
    margin-top: 20px;
    letter-spacing: 0.6px;
    @media (max-width: 992px) {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.33;
      margin-bottom: 11px;
      margin-top: 44px;
    }
  }

  .imageContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-right:10px;
    padding-left: 15px;

    @media(max-width:992px){
      position: relative;
      padding-left: 0;
      top: -10px;
      margin-left: 0;
      flex-direction: column;
      margin-bottom: 25px;
    }

    .col-lg-10{
      padding-left:10px;
    }
  }

  .leftCol{
    float:left;
    width:366px;
    margin-right:30px;

    img {
      margin-right:26px;
      margin-bottom:26px;
    }

    @media(max-width:992px){
      width:100%;
      float:none;
      img.last {
        margin-left: 0;
        padding-left:0;

      }
      div {
        padding-right:0;
      }

      img {
        margin-right: 0;
        margin-bottom:13px;
        padding-right:0;
        width: 100%;
        object-fit: contain;
        object-position: top left;
      }
    }
  }

  .rightCol{
    width:537px;
    float:left;

    @media (min-width: 769px) {
      width: unset;
    }

    .worker4 {
      height: 259px;

      @media (max-width: 992px) {
        height: 100%;
      }
    }

    img.last {
      padding-bottom:31px;
      padding-left:29px;
    }

    @media(max-width:768px){
      float:left;
      padding-left:0;
      width:100%;
      div{
        padding-right:0;
      }
      img {
        width: 100%;
        object-fit: cover;
        object-position: top center;
      }
      img.last{
        margin-left: 0;
        padding-bottom:13px;
        padding-left:0;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  @media(max-width:992px){
    text-align:center;
    margin:0;
  }

  .subtitle{
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: $semiblack;
    margin-bottom: 33px;

    @media(max-width:992px){
      max-width: 90%;
    }
  }

  .quoteContainer{
    margin-top:39px;
    display: flex;
    width: 100%;
    justify-content: center;

    @media (max-width: 992px) {
      margin-top: 0;
      margin-left: 0;
    }

    .workingText{
      padding-top:10px;
      position: relative;

      @media (max-width: 992px) {
        padding-left:0;
        padding-top: 35px;
      }

      .workingSignature{
        margin-top: 33px;

        @media (max-width: 992px) {
          margin-top:25px;
        }

        span, p {
          font-family: Roboto, sans-serif;
        }

        span {
          font-size: 14px;
        }

        p {
          font-size: 12px;
          margin-top: 5px;
          text-align: left;
          color: #575563;

          @media (max-width: 992px) {
            text-align: center;
            margin-top: 5px;
          }
        }
      }
    }

    .quotationBegin {
      position:absolute;
      color: #575563;
      height: 40px;
      left: -83px;
      top: 0;

      @media (max-width: 992px) {
        height: 20px;
        top: 0;
        left: 0;
      }
    }

    .quotationEnd {
      position:absolute;
      color: #575563;
      height: 40px;
      right: -83px;
      top: 20px;

      @media (max-width: 1440px) {
        right: -80px;
        top: 20px;
      }

      @media (max-width: 992px) {
        height: 20px;
        right: 0;
        top: 90px;
      }
    }

    .subtitle{
      font-family: Roboto, sans-serif;
      font-size: 18px;
      line-height: 1.56;
      text-align: left;
      color: $semiblack;
      max-width:574px;
      @media (max-width: 992px) {
        font-family: Roboto, sans-serif;
        font-size: 16px;
        line-height: 1.75;
        text-align: center;
        color: $semiblack;
      }
    }
  }
}
