@import "../../../common/styles";

.root {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 14% 40px;
  color: $semiblack;

  @media (max-width: 1450px) {
    padding: 20px 8% 40px;
  }

  @media (max-width: 768px) {
    padding: 20px;
  }

  h2 {
    font-size: 44px;
    font-weight: 700;
    letter-spacing: .6px;
    color: $semiblack;
    font-family: "Titillium Web", sans-serif;
    margin-top: 0;
    margin-bottom: 50px;

    @media (max-width: 768px) {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }

  h4 {
    font-family: "Titillium Web", sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.09;
    text-align: center;
    margin-bottom: 20px;
    color: #005daa;
    margin-top: 0px;

    @media (max-width: 992px) {
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 10px;
      font-weight: bold;
      line-height: 1.33;
      text-align: center;
    }
  }

  .content {
    vertical-align: top;
    display: inline-block;
    text-align: center;
    padding: 0 50px;
    max-width: 1450px;
    margin-top: 20px;

    @media (max-width: 1450px) {
      padding: 0;
    }

    @media (max-width: 768px) {
      text-align: center;
      margin-top: 20px;
    }

    p {
      font-family: Roboto, sans-serif;
      font-size: 16px;
      line-height: 26.08px;
      flex-grow: 1;
      max-width: 945px;
      margin: auto;

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 1.59;
      }
    }
  }

  .footText {
    color: $primary1;
    font-size: 18px;
    font-weight: 500;
    line-height: 28.08px;
    font-family: Roboto, sans-serif;
    text-align: center;
    -webkit-text-size-adjust: 100%;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
    }
  }

  /* Container for flexboxes */
  .row {
    display: flex;
    flex-wrap: wrap;
  }

  /* Create four equal columns */
  .column {
    flex: 25%;
    padding: 20px;
  }

  /* On screens that are 992px wide or less, go from four columns to two columns */
  @media screen and (max-width: 992px) {
    .column {
      flex: 50%;
    }
  }

  .logo_container{
    height: 100px;
    margin: 0;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .logo{
    max-width:100%;
    max-height:100px;

  }
}
