@import "../../../common/styles";

.root {
  padding: 33px 10% 0;
  @media (max-width: 992px) {
    padding: 4px 14px 0;
  }
  h3 {
    text-align: center;
    font-family: 'Titillium Web', sans-serif;
    font-size: 44px;
    font-weight: bold;
    line-height: 1.09;
    margin-bottom: 80px;
    color: $semiblack;
    letter-spacing: 0.7px;
    @media (max-width: 992px) {
      letter-spacing: 0.1px;
      font-size: 22px;
      font-weight: bold;
      line-height: 1.09;
      margin-bottom: 26px;
      text-align: center;
      margin-top: 0;
      padding: 0;
    }
  }
}

.brands {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    justify-content: space-around;
    flex-wrap: wrap;
  }

  img {
    margin-bottom: 20px;

    @media (max-width: 768px) {
      margin: 16px;
    }
  }

  >a {
    @media (max-width: 768px) {
      max-width: 150px;
      max-height: 40px;
      margin: 20px 20px 20px 0;
    }
  }
}

.wideBrands {
  img {
    @media (max-width: 768px) {
      margin: 16px;
    }
  }
}

.brandsMobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  > img {
    max-width: 40%;
    margin: 16px;
    min-width: 20%;

    &:last-child {
      max-width: 90%;
    }
  }
}
