@import "../../../common/styles";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 54px 7%;

  @media (max-width: 1480px) {
  }

  @media (max-width: 980px) {
    padding: 30px 18px;
  }

  h1 {
    font-family: "Titillium Web", sans-serif;
    font-weight: 400;
    font-size: 36px;
    max-width: 50%;
    text-align: center;
    margin-bottom: 36px;

    @media (max-width: 1750px) {
      max-width: 60%;
    }

    @media (max-width: 1480px) {
      max-width: 70%;
    }

    @media (max-width: 1200px) {
      max-width: 80%;
    }

    @media (max-width: 980px) {
      max-width: 83%;
      font-size: 24px;
      margin-bottom: 21px;
    }

    @media (max-width: 450px) {
      max-width: 75%;
    }

    @media (max-width: 376px) {
      max-width: 83%;
    }

    @media (max-width: 350px) {
      max-width: 100%;
    }

    span {
      font-weight: 700;
      color: $primary1;
      font-family: "Titillium Web", sans-serif;
    }
  }

  .imageContainer {
    
    cursor: pointer;

    img {
      max-width: 100%;
    }
  }
}
