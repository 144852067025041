@import "../../../common/styles";

.root {
    height: 430px;
    padding: 60px 10% 180px;
    background-color: #EBEBEB;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-family: 'Roboto';

    @media (max-width: 980px) {
        display: none;
    }

    section {
        display: flex;
        flex-direction: column;
        padding-left: 20px;

        span {
            margin: 0 0 28px;
            font-size: 14px;
        }

        h4 {
            font-family: Roboto, sans-serif;
            margin: 0 0 28px;
            font-size: 16px;
            font-weight: 700;
        }

        a {
            font-size: 14px;
            font-weight: 400;
            color: $semiblack;
            cursor: pointer;
            margin-bottom: 4px;
            text-decoration: none;
            white-space: nowrap;

            &:hover {
                text-decoration: underline;
            }
        }

        .terms {
            display: flex;

            :nth-child(1) {
                margin-right: 20px;
            }
        }

        .blueLink {
            color: $primary1;
        }
    }

    section:first-child {
        flex-grow: 1;
        padding-left: 0;
    }

    section:not(:first-child) {
        border-left: 1px solid white;
    }

    section:not(:last-child) {
        padding-right: 6%;

        @media (max-width: 1440px) {
            padding-right: 0;
            width: 15%;
        }
    }

    .noLink {
        cursor: default;
        text-decoration: none !important;
    }
}


