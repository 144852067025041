.title {
  @media (max-width: 992px) {
    &:after {
      font-family: "Glyphicons Halflings", sans-serif; /* essential for enabling glyphicon */
      float: right;
      /* symbol for "collapsed" panels */
      content: "\e113"; /* adjust as needed, taken from bootstrap.css */
      font-size: 12px;
      padding: 5px;
    }
    &.collapsed:after {
      font-family: "Glyphicons Halflings", sans-serif; /* essential for enabling glyphicon */
      float: right;
      content: "\e114"; /* adjust as needed, taken from bootstrap.css */
      font-size: 12px;
      padding: 5px;
    }
  }

  width: 200px;
  margin-top: 23px;
  margin-bottom: 22px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 1.33;
  text-align: left;
  color: #005daa;
  padding-bottom: 25px;
  border-bottom: solid 1px #d5d5d5;
  @media (max-width: 992px) {
    width: 100%;
  }
}
