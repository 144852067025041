@import "../../../common/styles";

.root {
  padding-bottom: 42px;
  padding-top: 10px;
  border-bottom: 1px solid #D5D5D5;

  @media (max-width: 992px) {
    padding: 17px 16px 48px;
  }

  h3 {
    margin-top: 46px;
    font-family: 'Titillium Web', sans-serif;
    font-size: 36px;
    letter-spacing: 0.7px;
    font-weight: bold;
    text-align: center;
    line-height: 1.33;
    color: $semiblack;
    margin-bottom: 40px;

    @media (max-width: 992px) {
      margin-top: 20px;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.33;
      text-align: center;
    }
  }

  .jobCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 992px) {
      flex-direction: column;
    }
  }

  .viewJobsButton{
    display: flex;
    align-items: center;
    width: 200px;
    height: 43px;
    padding: 12px 53px;
    z-index:1;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #005daa;
    text-align:center;
    text-decoration: none;

    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #005daa;
    margin-top: 42px;

    &:hover {
      text-decoration: underline;
    }

    @media (max-width: 992px) {
      margin-top: 30px;
    }
  }

  .noJobsMessage {
    text-align: center;
    max-width: 32%;

    @media (max-width: 1720px) {
      max-width: 35%;
    }
    @media (max-width: 1650px) {
      max-width: 38%;
    }
    @media (max-width: 1440px) {
      max-width: 42%;
    }
    @media (max-width: 1400px) {
      max-width: 45%;
    }
    @media (max-width: 1280px) {
      max-width: 48%;
    }
    @media (max-width: 992px) {
      max-width: 95%;
    }
  }
}
