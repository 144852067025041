@import "../../styles";

.root {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
  margin-top: 21px;
  margin-bottom: 26px;

  .inputWrapper {
    background-color: white;
    display: flex;
    border-radius: 3px;
    height: 46px;
    padding: 8px 8px 8px 12px;
    flex-grow: 1;
    margin-right: 24px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
    position: relative;

    @media (max-width: 768px) {
      margin: 0;
    }

    img {
      max-width: 20px;
    }

    input {
      width: 100%;
      outline: none;
      border: none;
      padding-left: 10px;
      font-family: Roboto, sans-serif;
      font-size: 16px;
    }

    ul {
      list-style-type: none;
      position: absolute;
      left: 0;
      width: 422px;
      margin-top: 52px;
      padding: 8px 0;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      text-align: left;
      font-size: 18px;
      z-index: 99;
      @media (max-width: 768px) {
        width: 100%;
      }

      li {
        padding: 4px;
        &:hover {
          background-color: lightgrey;
          cursor: pointer;
        }
      }
    }
  }

  .inputWrapper:focus-within {
    box-shadow: 0 0 1px 2px $primary1;
  }

  button {
    background-color: $primary1;
    color: white;
    border: none;
    border-radius: 3px;
    letter-spacing: 0.5px;
    padding: 12px 24px;
    font-size: 14px;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;

    @media (max-width: 768px) {
      margin-left: 12px;
    }

    &:hover {
      text-decoration: underline;

      &:disabled {
        text-decoration: none;
      }
    }
  }
}
.checkAnother {
  text-transform: none;
  text-decoration: underline;
  font-size: 14px;
  line-height: 1.71;
  color: #005daa;
  display: none;
}

.notificationBox {
  display: flex;
  width: 100%;
  border-radius: 3px;
  background-color: #ffffff;
  border: solid 1px #e9f3ff;
  padding: 24px;
  margin-top: 24px;
  margin-bottom: 12px;

  img {
    max-width: 32px;
    max-height: 32px;
    margin-right: 18px;
  }
}

.returnMessage {
  color: $primary2;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $primary1;
  }
}
