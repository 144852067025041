@import "../../../common/styles";

.root {
  width: 100%;
  position: relative;

  .cardsContainer {
    display: flex;
    padding: 0 7%;
    justify-content: center;
    position: absolute;
    top: -150px;
    width: 100%;

    @media (max-width: 980px) {
      flex-direction: column;
      position: relative;
      padding: 0;
      top: 0;
    }

    >:nth-child(1) {
      background: linear-gradient(225deg, transparent 20px, $primary1 20px);

      @media (max-width: 980px) {
        background: $primary1;
      }

      >span {
        background: linear-gradient(225deg, transparent 20px, $primary3 20px);
      }
    }
    >:nth-child(2) {
      background: linear-gradient(225deg, transparent 20px, $primary3 20px);

      @media (max-width: 980px) {
        background: $primary3;
      }

      >span {
        background: linear-gradient(225deg, transparent 20px, #4C9FE3 20px);
      }
    }
    >:nth-child(3) {
      background: linear-gradient(225deg, transparent 20px, #4C9FE3 20px);

      >span {
        background: linear-gradient(225deg, transparent 20px, #74B8F1 20px);
      }

      @media (max-width: 980px) {
        background: #4C9FE3;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 200px 40px 46px 40px;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 980px) {
      flex-direction: column;
      padding: 40px 20px 52px;
      max-height: 1240px;
      overflow: hidden;
      font-size: 16px;
      background-size: 100% auto;
      background-color: #EBEBEB;

      &.expanded {
        max-height: none;
      }
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary1;
    font-size: 44px;
    font-family: "Titillium Web", sans-serif;
    font-weight: 700;
    letter-spacing: 0.7px;
    margin-bottom: 25px;

    @media (max-width: 980px) {
      flex-direction: column;
      font-size: 28px;
      margin-bottom: 28px;
      flex: 0 0 auto
    }

    img {
      height: 96px;
      margin-bottom: 25px;
      margin-left: 23px;

      @media (max-width: 980px) {
        height: 83px;
        margin: 8px 0 0;
      }
    }
  }

  .subtitle {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: $semiblack;
    letter-spacing: .01px;
    font-family: Roboto, sans-serif;
    margin: 0 250px 49px;
    width: 65%;

    @media (max-width: 980px) {
      width: 100%;
      letter-spacing: 0.01px;
      line-height: 24px;
      font-weight: 400;
      margin: 0 0 73px;
    }
  }

  .cells {
    width: 95%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 980px) {
      flex-direction: column;
      align-items: center;
      display: none;
    }

    >div {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: 14px;
      font-family: Roboto, sans-serif;
      line-height: 22px;
      width: 25%;

      @media (max-width: 980px) {
        width: 100% !important;
        margin-bottom: 50px;
      }

      img {
        height: 180px;
      }

      p {
        font-weight: 300;
        @media (max-width: 980px) {
          margin: 0;
        }
      }
    }
  }

  .cellsTop {
    >div {
      >img {
        margin-bottom: 26px;
      }
      >p {
        margin: 0 40px;
        width: 80%;

        @media (min-width: 1500px) {
          margin-bottom: 30px;
        }

        @media (max-width: 1440px) {
          margin: 0 10px 23px;
        }

        @media (max-width: 980px) {
          margin: 0;
        }
      }

      >span {
        min-height: 40px;
        width: 3px;
        flex-grow: 1;
        background-color: $primary3;
        align-self: flex-start;
      }
    }

    >:first-child {
      width: 20%;
    }

    @media (max-width: 1440px) {
      width: 100%;
    }
  }

  .cellsBottom {
    >div {
      justify-content: flex-start;

      >div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        >p {
          width: 90%;
          margin-bottom: 25px;
        }
      }

      >span {
        height: 45px;
        width: 3px;
        background-color: $primary3;
        margin-bottom: 23px;
        align-self: flex-start;
      }
    }
  }

  .cellsMobile {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;

    @media (min-width: 981px) {
      display: none;
    }

    >div {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 40px;

      span {
        font-size: 30px;
        font-weight: 700;
        color: $semiblack;
        margin-bottom: 24px;
        line-height: 24px;
      }

      img {
        margin-bottom: 20px;
        height: 184px;
      }

      p {
        font-size: 14px;
        color: $semiblack;
        line-height: 22.4px;
        margin-bottom: 0;
      }
    }
  }

  .timeline {
    background-color: $primary3;
    margin-left: -4%;
    width: 100%;
    height: 48px;
    font-size: 24px;
    font-weight: 900;
    color: white;
    font-family: Roboto sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    align-self: start;
    padding: 0 6% 0 10%;

    @media (max-width: 980px) {
      display: none;
    }

    > div {
      display: flex;
      justify-content: space-between;
      width: 16%;

      >span {
        position: relative;
      }
    }

    .timelineCorner {
      position: absolute;
      right: -24px;
      top: 0;
      border-top: 24px solid transparent;
      border-bottom: 24px solid transparent;
      border-left: 24px solid $primary3;
      width: 20px;
    }
  }

  .learnMore {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid $primary3;
    color: $primary1;
    text-decoration: none;
    font-size: 16px;
    height: 43px;
    font-weight: 700;
    font-family: Roboto, sans-serif;
    align-self: center;
    padding: 12px 25px;
    margin-top: 54px;

    &:hover {
      text-decoration: underline;
    }

    @media (max-width: 980px) {
      margin-top: -10px;
    }
  }
}
