@import "../../../common/styles";

.root {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 14% 86px;
  color: $semiblack;

  @media (max-width: 1450px) {
    padding: 56px 8% 86px;
  }

  @media (max-width: 768px) {
    padding: 30px 20px 40px;
  }

  h2 {
    font-size: 44px;
    font-weight: 700;
    letter-spacing: .6px;
    color: $semiblack;
    font-family: "Titillium Web", sans-serif;
    margin-top: 0;
    margin-bottom: 50px;

    @media (max-width: 768px) {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    padding-left: 150px;
    margin-bottom: 40px;
    width: 100%;

    @media (max-width: 1450px) {
      padding: 0;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 30px;
    }

    img {
      margin-right: 60px;
      flex-shrink: 0;

      @media (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 32px;
      }
    }

    p {
      font-family: Roboto, sans-serif;
      font-size: 16px;
      line-height: 26.08px;
      flex-grow: 1;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 1.59;
      }
    }
  }

  .footText {
    color: $primary1;
    font-size: 18px;
    font-weight: 500;
    line-height: 28.08px;
    font-family: Roboto, sans-serif;
    text-align: center;
    -webkit-text-size-adjust: 100%;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
    }
  }
}
