@import '../../../common/styles';

.root {
  width: 100%;
  background-color: #F3F3F3;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 54px 7%;

  @media (max-width: 980px) {
    padding: 30px 14px;
  }

  >h2 {
    color: $semiblack;
    font-weight: 700;
    font-family: "Titillium Web", sans-serif;
    font-size: 44px;
    margin-bottom: 54px;

    @media (max-width: 980px) {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }

  .cards {
    display: flex;
    flex-direction: column;
    margin-bottom: 46px;

    @media (max-width: 980px) {
      width: 100%;
    }
  }

  .cardRow {
    display: flex;
    justify-content: unset;
    margin-bottom: 30px;

    @media (max-width: 980px) {
      flex-direction: column;

      >div>img {
        height: unset;
      }

      :nth-child(2) {
        >p {
          max-width: 85%;
        }
      }

      :nth-child(3), :nth-child(5), :nth-child(6) {
        >p {
          max-width: 80%;
        }
      }
      :nth-child(4) {
        >p {
          max-width: 75%;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
    flex-wrap: wrap;
  }

  .quote {
    width: 45%;
    color: $semiblack;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @media (max-width: 1800px) {
      width: 50%;
    }

    @media (max-width: 1440px) {
      width: 65%;
    }

    @media (max-width: 1300px) {
      width: 70%;
    }

    @media (max-width: 980px) {
      width: 100%;
      padding: 50px 0;
    }

    >p {
      width: 100%;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 17px;

      @media (max-width: 980px) {
        width: 100%;
      }
    }

    >span {
      font-size: 14px;
    }

    img {
      width: 26px;
      height: 21px;
      fill: #575563;
      position: absolute;
      top: 16px
    }

    >.leftMark {
      left: -53px;
      top: 0;

      @media (max-width: 980px) {
        left: 12px;
      }
    }
    >.rightMark {
      right: -53px;
      top: 0;

      @media (max-width: 980px) {
        right: 12px;
        bottom: 0;
        top: unset;
      }
    }
  }
}
