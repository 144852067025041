@import "../../common/styles";

.root {
  width: 100%;
  overflow: hidden;
}

.main {
  width: 100%;
  height: 800px;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;

  @media(max-width: 992px) {
    background-position: center;
    padding-top: 120px;
    height: 615px;
    text-align: center;
  }
}

.quoteWrapper {
  position: absolute;
  top: 21vw;
  left: 14%;
  width: 750px;

  @media (max-width: 1500px) {
    top: 26vw;
    width: 45%;
  }

  @media (max-width: 1280px) {
    top: 30vw;
    width: 50%;
  }

  @media (max-width: 980px) {
    left: 35px;
    top: 300px;
    width: 85%;
    text-align: left;
  }

  @media (max-width: 420px) {
    top: 70vw;
    width: 85%;
  }

  @media (max-width: 380px) {
    top: 77vw;
  }

  @media (max-width: 375px) {
    top: 78vw;
  }

  @media (max-width: 365px) {
    top: 82vw;
    width: 90%;
  }

  @media (max-width: 320px) {
    top: 90vw;
  }

  @media (max-width: 280px) {
    top: 45%;
  }

  .quote {
    font-size: 36px;
    font-weight: 700;
    color: white;
    font-family: "Titillium Web", sans-serif;
    margin-bottom: 34px;
    display: inline-block;
    position: relative;

    @media (max-width: 980px) {
      margin-bottom: 56px;
      line-height: 44px;
    }

    @media (max-width: 320px) {
      font-size: 24px;
    }
  }

  .quoteDescription {
    color: white;
    display: flex;
    flex-direction: column;

    .author {
      font-size: 18px;
      font-family: "Titillium Web", sans-serif;
    }
    .position {
      font-size: 14px;
      font-family: "Titillium Web", sans-serif;
    }
  }

  .quotationMarkOpen {
    position: absolute;
    top: -4px;
    left: -70px;
    height: 50px;
    width: 40px;

    @media (max-width: 980px) {
      top: -70px;
      left: 0;
    }
  }

  .quotationMarkClose {
    position: absolute;
    bottom: 40px;
    right: -3%;
    height: 50px;
    width: 40px;

    @media (max-width: 1450px) {
      bottom: -20px;
    }

    @media (max-width: 980px) {
      bottom: -60px;
      right: 20px;
    }

    @media (max-width: 320px) {
      bottom: -100px;
      right: 30px;
    }
  }
}
